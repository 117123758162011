import React, { useCallback, useRef, useState } from "react";
import AdminHeader from "../admin/AdminHeader";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import Box from "@mui/material/Box";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getstorelist } from "../API";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import Loader from "../components/Popups/Loader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TotalOrdersChart from "../components/Charts/TotalOrdersChart";
import TotalSalesChart from "../components/Charts/TotalSalesChart";
import AverageCartChart from "../components/Charts/AverageCartChart";
import Calendar from "../components/Calendar/Calendar";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import * as XLSX from "xlsx";
import StoreUptime from "../components/Charts/StoreUptime";
import { HorizontalBar } from "../components/Charts/HorizontalBar";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Reports(props) {
  let token = localStorage.getItem("token");

  const navigate = useNavigate();
  const [, setorderaccepted] = useState(false);
  // const[cancelorder,setcancelorder] =useState(false)
  const [orderlist, setorderlist] = useState();
  const [reportOrderList, setReportOrderList] = useState();
  const [refresh, setrefresh] = useState(false);
  const size = 10;
  const [page, setpage] = useState(1);
  const [showcount, setshowcount] = useState();
  const [Paginationlength, setPaginationlength] = useState(false);
  const [currentpage, setcurrentpage] = useState(1);
  const [storelistData, setStorelistData] = useState();
  const [reportsorderlist, setreportsorderlist] = useState();
  const [mostFrequentDropId, setmostFrequentDropId] = useState(null);
  const [bestseller, setbestseller] = useState();
  const [bestsellerno, setbestsellerno] = useState();
  const [loaderState, setloaderstate] = useState(false);
  //  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [, setOpen] = useState(false);
  const [currentStore, setcurrentStore] = useState();
  const [currentStoreType, setCurrentStoreType] = useState();
  const [currentStoreTimezone, setCurrentStoreTimezone] =
    useState("America/New_York");
  const [currentCurrency, setCurrentCurrency] = useState();
  const { startDate, endDate } = useSelector(
    (state) => state.datefilterreducer
  );
  const columnsToExclude = [
    "storeId",
    "statusCode",
    "currency",
    "ottonomyHit",
    "language",
    "pin",
    "paymentRefrence",
    "cancellationReason",
    "OrderData",
  ];

  const exportToExcel = (data, fileName, storeType, storeTimezone) => {
    // Create a new array to hold the rows for the Excel sheet
    const rows = [];
    let filteredData = []
    if (storeType === "self") {
      data.forEach((order) => {
        // if(order.status !== "order cancelled") {
        let order_date = new Date(order.createdAt).toLocaleString("en-GB", {
          // hour: "numeric",
          // minute: "numeric",
          // second: "numeric",
          timeZoneName: "short",
          timeZone: currentStoreTimezone,
        });

        const newRow = {
          OrderID: order.orderId,
          Username: order.userName,
          Status: order.status,
          "Mobile Number": order.mobileNumber,
          OrderData: "",
          "Pre-Tax Amount": `${order.currency} ${parseFloat(
            order.cartTotal
          ).toFixed(2)}`,
          Tax: Boolean(parseFloat(order.tax))?`${order.currency} ${order.tax}`:'-',
          "Pre-Discount Amount": `${order.currency} ${(
            parseFloat(order.cartTotal) + parseFloat(order.tax)
          ).toFixed(2)}`,
          "Payment Gateway Fixed Fees": `${order.currency} 0.3`,
          "Payment Gateway Variable Fees": `${order.currency} ${(
            (parseFloat(order.cartTotal) + parseFloat(order.tax)) *
            0.029
          ).toFixed(2)}`,
          Payout: `${order.currency} ${(
            parseFloat(order.cartTotal) -
            0.3 -
            (parseFloat(order.cartTotal) + parseFloat(order.tax)) * 0.029
          ).toFixed(2)}`,
          "Delivery Fee": order?.deliveryfee?`${order.currency} ${(order?.deliveryfee).toFixed(2)}`:'-',
          "Discounted Amount":order.discount?`${order.currency} ${(
            parseFloat(order.discount)
          ).toFixed(2)}`:'-',
          "Total Amount":`${order.currency} ${(
            parseFloat(order.totalAmount)
          ).toFixed(2)}`,
          "Pickup Location": order.pickup.locationName&&Object.values(order.pickup.locationName)[0],
          "Delivery Location": order.drop.locationName&&Object.values(order.drop.locationName)[0],
          "Order Date": order_date.split(",")[0],
          "Order Time": order_date.split(",")[1],
        };

        if (!process.env.REACT_APP_STRIPE_PAYOUT_DETAILS) {
          delete newRow["Payment Gateway Fixed Fees"];
          delete newRow["Payment Gateway Variable Fees"];
          delete newRow["Payout"];
        }

        if(!storelistData[0]?.showDeliveryFee) {
          delete newRow["Delivery Fee"];
        }

        // Concatenate the name and quantity values from orderData array
        newRow.OrderData = order.orderData
          .map(
            (item) =>
              `${
                item.name.includes("x1") ? item.name.split("x1")[0] : item.name
              } X${item.quantity}`
          )
          .join(", ");

        rows.push(newRow);
        // }
      });
      // Calculate the sum of all totalAmount fields
      function isColumnEmpty(column) {
        return column.every(value => value === 0 || value === '-'||value === undefined||value === "");
      }
      
      function filterEmptyColumns(data) {
        const columns = Object.keys(data[0]);
        const nonEmptyColumns = columns.filter(column => !isColumnEmpty(data.map(row => row[column])));
        if(!nonEmptyColumns.includes('Discounted Amount')){
          nonEmptyColumns.splice(nonEmptyColumns.indexOf('Pre-Discount Amount'), 1);
        }
        if(!nonEmptyColumns.includes('Tax')){
          nonEmptyColumns.splice(nonEmptyColumns.indexOf('Pre-Tax Amount'), 1);
        }
        return data.map(row => {
          const filteredRow = {};
          nonEmptyColumns.forEach(column => {
            filteredRow[column] = row[column];
          });
          return filteredRow;
        });
      }
      filteredData = filterEmptyColumns(rows);
      const totalPreTaxAmount = data.reduce(
        (sum, order) => sum + parseFloat(order.cartTotal),
        0
      );
      const totalPreDiscountAmount = data.reduce(
        (sum, order) => sum + parseFloat(order.cartTotal),
        0
      );
      const totalDiscountedAmount = data.reduce(
        (sum, order) => {
          if(order?.discount){
            return sum + parseFloat(order?.discount,10)
          }
          return sum+0
        },
        0
      );
      const totaltaxsum = data.reduce(
        (sum, order) => sum + parseFloat(order.tax),
        0
      );

      const totalDeliveryFee = data.reduce(
        (sum, order) => sum + parseFloat(order?.deliveryfee ?? 0),
        0
      );

      const totalAmountSum = data.reduce(
        (sum, order) =>
          sum + (parseFloat(order.totalAmount) + parseFloat(order.tax)),
        0
      );

      const paymentGatewayFixedFees = data.reduce((sum, order) => sum + 0.3, 0);

      const paymentGatewayVariableFees = data.reduce(
        (sum, order) =>
          sum + (parseFloat(order.cartTotal) + parseFloat(order.tax)) * 0.029,
        0
      );

      const totalPayout = data.reduce(
        (sum, order) =>
          sum +
          (parseFloat(order.cartTotal) -
            0.3 -
            (parseFloat(order.cartTotal) + parseFloat(order.tax)) * 0.029),
        0
      );
      // Add the "Sub Total" row
      const subTotalRow = {
        OrderData: "Total",
        "Pre-Tax Amount": `${data[0]?.currency} ${totalPreTaxAmount.toFixed(
          2
        )}`,
        "Pre-Discount Amount": `${data[0]?.currency} ${totalPreDiscountAmount.toFixed(
          2
        )}`,
        "Discounted Amount": `${data[0]?.currency} ${totalDiscountedAmount.toFixed(2)}`,
        Tax: `${data[0]?.currency} ${totaltaxsum.toFixed(2)}`,
        "Delivery Fee": `${
          data[0]?.currency
        } ${totalDeliveryFee.toFixed(2)}`,
        "Total Amount": `${data[0]?.currency} ${totalAmountSum.toFixed(2)}`,
        "Payment Gateway Fixed Fees": `${
          data[0]?.currency
        } ${paymentGatewayFixedFees.toFixed(2)}`,
        "Payment Gateway Variable Fees": `${
          data[0]?.currency
        } ${paymentGatewayVariableFees.toFixed(2)}`,
        Payout: `${data[0]?.currency} ${totalPayout.toFixed(2)}`,
      };
      if (!process.env.REACT_APP_STRIPE_PAYOUT_DETAILS) {
        delete subTotalRow["Payment Gateway Fixed Fees"];
        delete subTotalRow["Payment Gateway Variable Fees"];
        delete subTotalRow["Payout"];
      }

      if(!totalDeliveryFee||!storelistData[0]?.showDeliveryFee) {
        delete subTotalRow["Delivery Fee"];
      }
      if(!totalDiscountedAmount) {
        delete subTotalRow["Discounted Amount"];
        delete subTotalRow["Pre-Discount Amount"];
      }
      if(!totaltaxsum) {
        delete subTotalRow["Tax"];
        delete subTotalRow["Pre-Tax Amount"];
      }

      filteredData.push({});
      filteredData.push(subTotalRow);
    } else {
      // console.log("NOT SELF")
      data.forEach((order) => {
        // if(order.status !== "order cancelled") {
        let order_date = new Date(order.createdAt).toLocaleString("en-GB", {
          // hour: "numeric",
          // minute: "numeric",
          // second: "numeric",
          timeZoneName: "short",
          timeZone: currentStoreTimezone,
        });

        const newRow = {
          OrderID: order.orderId,
          Username: order.userName,
          Status: order.status,
          "Mobile Number": order.mobileNumber,
          "Pickup Location": order.pickup.locationName&&Object.values(order.pickup.locationName)[0],
          "Delivery Location": order.drop.locationName&&Object.values(order.drop.locationName)[0],
          "Delivery Time": order.deliveryTime,
          "Order Date": order_date.split(",")[0],
          "Order Time": order_date.split(",")[1],
        };
        filteredData.push(newRow);
        // }
      });
    }
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const columnWidths = filteredData.reduce((acc, row) => {
      Object.keys(row).map((key, colIndex) => {
        const cellContent = row[key];
        const cellLength = cellContent ? cellContent.toString().length : 0;
        acc[colIndex] = Math.max(acc[colIndex] || 0, cellLength);

        return null;
      });
      return acc;
    }, []);

    // Set column widths in the !cols property of the worksheet
    worksheet["!cols"] = columnWidths.map((width) => ({ width: width + 2 }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "sheet1");
    XLSX.writeFile(workbook, fileName + ".xlsx");
  };

  const handleExportClick = () => {
    axios
      .post(
        `${process.env.REACT_APP_ORDERAT}/order/list`,
        {
          page: 0,
          pageSize: 0,
          storeId: currentStore,
          startDate: new Date(startDate ?? new Date() )?.toLocaleDateString("en-US"),
          endDate: new Date(endDate ?? new Date() )?.toLocaleDateString("en-US"),
          timezone: currentStoreTimezone
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log("resornf", res);
        //  excelData.push(res.data?.order?.filter((item) => item.status !== "booked"))
        // console.log("EXPORT RES", res)
        exportToExcel(
          res.data?.order?.filter((item) => item.status !== "booked"),
          `order_list - ${
            startDate
              ? new Date(startDate)
                  .toLocaleString("en-GB", {
                    // hour: "numeric",
                    // minute: "numeric",
                    // second: "numeric",
                    // timeZoneName: "short",
                    // timeZone: currentStoreTimezone,
                  })
                  .split(",")[0]
              : new Date()
                  .toLocaleString("en-GB", {
                    // hour: "numeric",
                    // minute: "numeric",
                    // second: "numeric",
                    // timeZoneName: "short",
                    // timeZone: currentStoreTimezone,
                  })
                  .split(",")[0]
          } - ${
            endDate
              ? new Date(endDate)
                  .toLocaleString("en-GB", {
                    // hour: "numeric",
                    // minute: "numeric",
                    // second: "numeric",
                    // timeZoneName: "short",
                    // timeZone: currentStoreTimezone,
                  })
                  .split(",")[0]
              : new Date()
                  .toLocaleString("en-GB", {
                    // hour: "numeric",
                    // minute: "numeric",
                    // second: "numeric",
                    // timeZoneName: "short",
                    // timeZone: currentStoreTimezone,
                  })
                  .split(",")[0]
          }`,
          currentStoreType,
          currentStoreTimezone
        );
      })
      .catch((err) => {});
  };

  const filteredOrdersByPage = () => {
    if (!startDate || !endDate) {
      return orderlist;
    }

    const start = new Date(
      new Date(startDate).toLocaleString("en-US", {
        timeZone: currentStoreTimezone,
      })
    );
    const end = new Date(
      new Date(endDate).toLocaleString("en-US", {
        timeZone: currentStoreTimezone,
      })
    );

    return orderlist?.filter((order) => {
      const orderDate = new Date(
        new Date(order.createdAt).toLocaleString("en-US", {
          timeZone: currentStoreTimezone,
        })
      );

      return orderDate >= start && orderDate <= end;
    });
  };

  const filteredOrders = orderlist;

  const theme = createTheme({
    components: {
      MuiPagination: {
        styleOverrides: {
          ul: {
            "& .Mui-selected": {
              backgroundColor: "#00B7D4 !important",
              color: "white",
              "&:hover": {
                backgroundColor: "#0099C0",
              },
            },
          },
        },
      },
    },
  });

  const handleChangetwo = (event, value) => {
    setpage(value);
    setcurrentpage(value);
  };

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    getstorelist()
      .then((res) => {
        if (res.data.status === 200 || res.data.status === "200") {
          setStorelistData(res.data.message);
          setcurrentStore(res.data?.message[0]?.storeId);
          setCurrentStoreType(res.data?.message[0]?.type);
          setCurrentStoreTimezone(res.data?.message[0]?.timezone);
          setCurrentCurrency(res.data?.message[0]?.currency);
        } else {
          setStorelistData([]);
        }
      })
      .catch((err) => {
        console.log("Error in Store list", err);
      });
  }, []);

  useEffect(() => {
    setorderaccepted(true);
    setrefresh(!refresh);
  }, []);

  const updateState = useCallback(async () => {
    if (token) {
      return axios
        .post(
          `${process.env.REACT_APP_ORDERAT}/order/list`,
          {
            storeId: currentStore,
            page: page,
            pageSize: size,
            startDate: new Date(startDate ?? new Date() )?.toLocaleDateString("en-US"),
            endDate: new Date(endDate ?? new Date() )?.toLocaleDateString("en-US"),
            timezone: currentStoreTimezone
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // setTotalOrders(res.data.data.count)
          // setorderlist(res.data.data.order)
          setorderlist(
            res.data.order?.filter((item) => item.status !== "booked")
          );
          // console.log('res.data.order',res)
          if (res.data?.count > size) {
            setPaginationlength(true);
            // setPaginationlength(Paginationlength => Paginationlength+1)
          }
          // debugger
          if (res.data.count % size === 0) {
            setshowcount(res.data.count / size);
            // console.log('res.data.data.count%1',res.data.data.count%1)
            // console.log('showcount',showcount)
          } else {
            setshowcount(Math.floor(res.data.count / size) + 1);
            // setPaginationlength(false);
          }
        })
        .catch((err) => {
          console.log("Error in Order list", err);
        });
    }
    return null;
  }, [currentpage, size, currentStore, startDate, endDate, token]);

  useEffect(() => {
    const currentDate = new Date();
    const sevenDaysBefore = new Date();
    sevenDaysBefore.setDate(currentDate.getDate() - 6);
    axios
      .post(
        `${process.env.REACT_APP_ORDERAT}/order/analytics`,
        {
          storeId: currentStore,
          startDate: new Date(startDate ?? sevenDaysBefore)?.toLocaleDateString(
            "en-US"
          ),
          endDate: new Date(endDate ?? new Date())?.toLocaleDateString("en-US"),
          timeZone: currentStoreTimezone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // setTotalOrders(res.data.data.count)
        // setorderlist(res.data.data.order)
        // console.log(res.data.order, "REPORT ORDER LIST");
        setReportOrderList(res.data.order[0]);
        setpage(1);
        setcurrentpage(1);
      })
      .catch((err) => {
        console.log("Error in Order list", err);
      });
  }, [startDate, endDate, currentStore]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);

  const getStatusclass = (orderStatus) => {
    switch (orderStatus) {
      case "InProgress":
        return "p_inprogress";
      case "booked":
        return "";
      case "order delivered":
        return "p_delivered";
      case "order accepted":
        return "p_delivered";
      case "order cancelled":
        return "p_cancelled";
      case "New":
        return "p_new";
      case "order confirmed":
        return "p_new";
      default:
        return "p_inprogress";
    }
  };

  return (
    <>
      <div className="DesktopMain-wrapper">
        {loaderState ? (
          <>
            {" "}
            <Loader handleClose={handleClose} />
          </>
        ) : null}
        {/* {console.log('filteredOrders',filteredOrders)}   */}
        <AdminHeader reports={true} storelistData={storelistData} confirmOrder={props?.confirmOrder} />
        <div className="container padding-top">
          <div className="row">
            <div className="" style={{ textAlign: "left" }}>
              <div className="AdminDashboard-tabs-left-side">
                <>
                  <div className="AdminDashboard-tabs-left-side_heading">
                    <h1>
                      {" "}
                      Reports <ChevronRightOutlinedIcon />
                      {storelistData?.length > 1 ? (
                        <span>
                          <Dropdown
                            onSelect={(eventKey) => {
                              const selectedItem = storelistData?.find(
                                (item) => item.storeId === eventKey
                              );
                              setcurrentStore(selectedItem.storeId);
                              setCurrentStoreType(selectedItem.type);
                              // console.log(selectedItem, "SELECTED");
                              setCurrentStoreTimezone(selectedItem.timezone);
                            }}
                            id="Store_dropdown_select"
                          >
                            <Dropdown.Toggle id="dropdown-basic">
                              {currentStore}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="custom-dropdown-menu">
                              {storelistData?.map((item) => (
                                <Dropdown.Item
                                  key={item.storeId}
                                  eventKey={item.storeId}
                                >
                                  {item.storeId}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </span>
                      ) : (
                        <span> {currentStore}</span>
                      )}
                    </h1>
                    <Calendar currentStoreTimezone={currentStoreTimezone} />
                  </div>
                </>
              </div>
            </div>
          </div>
          <div className="row">
            {currentStoreType === "self" && (
              <>
                <div className="col-md-4">
                  <div className="Reports_page_graph_div">
                    {reportOrderList?.dateWiseOrders?.totalSalesVsDate
                      ?.length ? (
                      <>
                        <TotalSalesChart
                          analyticsData={reportOrderList?.dateWiseOrders}
                          currentStore={currentStore}
                          currentStoreTimezone={currentStoreTimezone}
                          currentCurrency={currentCurrency?.symbol}
                        />
                      </>
                    ) : (
                      <div>
                        <h5> Total Sales</h5>
                        <p> No Data to show </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="Reports_page_graph_div">
                    {reportOrderList?.dateWiseOrders?.averageCartVsDate
                      ?.length ? (
                      <AverageCartChart
                        analyticsData={reportOrderList?.dateWiseOrders}
                        currentStore={currentStore}
                        currentStoreTimezone={currentStoreTimezone}
                        currentCurrency={currentCurrency?.symbol}
                      />
                    ) : (
                      <div>
                        <h5>Average Cart Values </h5>
                        <p> No Data to show </p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="col-md-4">
              <div className="Reports_page_graph_div">
                {reportOrderList?.dateWiseOrders?.totalOrdersVsDate?.length ? (
                  <TotalOrdersChart
                    analyticsData={reportOrderList?.dateWiseOrders}
                    currentStore={currentStore}
                    currentStoreTimezone={currentStoreTimezone}
                  />
                ) : (
                  <div>
                    <h5> Total Orders</h5>
                    <p> No Data to show </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row OrderData_div">
            <div className="col-md-12">
              <div className="row">
                {reportOrderList?.uniqueCustomers?.totalUniqueCustomers && (
                  <div className="col-md-6">
                    <div className="reports_page_content_div reports_page_content_div_first">
                      <img src="/assets/images/customers.svg" alt="" />
                      <p> Total Customer </p>
                      <h5>
                        {reportOrderList?.uniqueCustomers?.totalUniqueCustomers}
                      </h5>
                    </div>
                  </div>
                )}
                {reportOrderList?.totalOrders && (
                  <div className="col-md-6">
                    <div className="reports_page_content_div reports_page_content_div_first">
                      <img src="/assets/images/orders.svg" alt="" />
                      <p> Total Orders</p>
                      {reportOrderList ? (
                        <>
                          <div className="d_flex_center">
                            <div>
                              <h5>
                                {reportOrderList?.totalOrders?.ordersDelivered}
                                <span> Delivered</span>
                              </h5>
                            </div>
                            <div className="reports_page_content_canceldiv">
                              <h5>
                                {reportOrderList?.totalOrders?.ordersCancelled}
                                <span> Cancelled</span>
                              </h5>
                            </div>
                          </div>
                        </>
                      ) : (
                        <> No Data to show</>
                      )}
                    </div>
                  </div>
                )}
                {currentStoreType === "self" && reportOrderList?.bestSeller && (
                  <div className="col-md-6">
                    <div className="reports_page_content_div reports_page_content_div_first">
                      <img src="/assets/images/products.svg" alt="" />
                      <p> Best Seller </p>
                      {reportOrderList ? (
                        <>
                          <h5> {reportOrderList?.bestSeller?.productName}</h5>
                        </>
                      ) : (
                        <>No data to show</>
                      )}
                    </div>
                  </div>
                )}
                {reportOrderList?.mostDeliveredLocation && (
                  <div className="col-md-6">
                    <div className="reports_page_content_div reports_page_content_div_first">
                      <img src="/assets/images/location.svg" alt="" />
                      <p> Most Delivered Location</p>
                      {reportOrderList ? (
                        <>
                          <h5>
                            {" "}
                            {
                              reportOrderList?.mostDeliveredLocation
                                ?.locationName&&Object.values(reportOrderList?.mostDeliveredLocation
                                  ?.locationName)[0]
                            }
                          </h5>
                        </>
                      ) : (
                        <>No Data to show</>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <StoreUptime
                    currentStore={currentStore}
                    currentStoreTimezone={currentStoreTimezone}
                  />

                </div>
              </div>
            </div> */}
          </div>
          <div className="row reports_page_orderlist_table">
            <div className="col-md-12">
              <div className="Zone_page_content_wrapper_main_div">
                <div className="Zone_page_filter_div_wrapper">
                  <h3> Orders List</h3>
                  {filteredOrders?.length > 0 && (
                    <button
                      onClick={handleExportClick}
                      className="flex Modal-button-Main-btn_yes"
                    >
                      {" "}
                      <img
                        className="Export_excel_image "
                        src="/assets/images/Export_excel.svg"
                        alt=""
                      />
                      Export Excel
                    </button>
                  )}
                </div>
              </div>
              <div className="Stores_Page_store_list_table">
                {filteredOrders?.length > 0 ? (
                  <>
                    <table
                      className="Store_page_table"
                      cellPadding="0"
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th> OrderId</th>
                          <th> Status </th>
                          <th> Delivery Gate</th>
                          {currentStoreType === "self" && <th> Order Info</th>}

                          {/* <th> Reason</th> */}
                          <th> Time Stamp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredOrders?.map((item, index) => {
                          if (item.storeId === currentStore) {
                            return (
                              <tr key={index}>
                                <td style={{ cursor: "pointer" }}>
                                  {" "}
                                  {item.orderId}
                                </td>
                                <td style={{ cursor: "pointer" }}>
                                  <Tooltip
                                    placement="top-end"
                                    title={`${
                                      item.status === "order cancelled"
                                        ? `Cancellation Reason : ${item?.cancellationReason?.text} ${item?.cancellationReason?.unavailableItems?.length? `\nunavailable items: ${item?.cancellationReason?.unavailableItems}`:""}`
                                        : ""
                                    }`}
                                  >
                                    <p className={getStatusclass(item.status)}>
                                      {" "}
                                      {item.status}
                                    </p>
                                  </Tooltip>
                                </td>
                                <td> {item.drop.locationName&&Object.values(item.drop.locationName)[0]} </td>
                                {currentStoreType === "self" && (
                                  <td style={{ color: "#3CB0CC" }}>
                                    {" "}
                                    {item?.orderData?.map((itm, index) => {
                                      return (
                                        <p
                                          key={"OrderInfo" + index}
                                          className="OrderInfo_p"
                                        >
                                          {itm?.name?.includes("x1")
                                            ? itm?.name?.split("x1")[0]
                                            : itm?.name}
                                          <strong>X{itm?.quantity}</strong>
                                        </p>
                                      );
                                    })}{" "}
                                  </td>
                                )}

                                {/* <td style={{ textAlign: "center" }}>
                                  {" "}
                                  {item.reason}{" "}
                                </td> */}
                                <td>
                                  {new Date(item.createdAt).toLocaleString(
                                    "en-GB",
                                    {
                                      // hour: "numeric",
                                      // minute: "numeric",
                                      // second: "numeric",
                                      timeZoneName: "short",
                                      timeZone: currentStoreTimezone,
                                    }
                                  )}
                                  {/* {new Date(item.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      timeZone: currentStoreTimezone,
                                    }
                                  )}
                                  {/* {new Date(item.createdAt)
                                    .toString()
                                    .replace(
                                      "GMT+0530 (India Standard Time)",
                                      ""
                                    )} */}
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                    <div>
                      {Paginationlength && filteredOrders.length > 0 ? (
                        <ThemeProvider theme={theme}>
                          <Pagination
                            className="PaginationWrapper_two"
                            count={showcount}
                            page={page}
                            onChange={handleChangetwo}
                            sx={{ width: "100%" }}
                          />
                        </ThemeProvider>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <p> No Orders available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
