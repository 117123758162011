import { XMLNode } from '../xml-node';
export default class DocumentNode extends XMLNode {
    constructor(node) {
        super(node);
    }
    open(bufferBuilder) {
        if (this.attributes.reverse)
            bufferBuilder.startReverseMode();
        return bufferBuilder;
    }
    close(bufferBuilder) {
        bufferBuilder.endReverseMode();
        return bufferBuilder;
    }
}
