import { XMLNode } from '../xml-node';
export default class PrintModeNode extends XMLNode {
    constructor(node) {
        super(node);
    }
    open(bufferBuilder) {
        if (this.attributes.mode === 'U220') {
            bufferBuilder.setPrintMode(false);
        }
        else {
            bufferBuilder.setPrintMode(true);
        }
        return bufferBuilder;
    }
    close(bufferBuilder) {
        bufferBuilder.resetAlign();
        return bufferBuilder;
    }
}
