import React, { useEffect, useState } from "react";
import AcceptSuccess from "./Accept/AcceptSuccess";
import NewOrder from "./Modals/NewOrder";
import Inprogress from "./Inprogress/Inprogress";
import CancelOrder from "./Cancel/CancelOrder";
import Booked from "./Booked/Booked";

function TabPanelInner(props) {
  const [robotSpawn, setrobotspawn] = useState();

  const getrobotSpawn = (robotSpawn) => {
    switch (robotSpawn) {
      case 0:
        return setrobotspawn("trying to spawn robot");
      case 1:
        return setrobotspawn("robot will reach pickup soon");
      case 2:
        return setrobotspawn("robot spawn has failed please contact support");
      default:
        return "";
    }
  };

  useEffect(() => {
    getrobotSpawn(props.data.robotSpawn);
  }, [props.data.robotSpawn]);

  const getcontent = (orderStatus) => {
    switch (orderStatus.toLowerCase()) {
      case "order cancelled":
        return (
          <CancelOrder data={props.data} storelistData={props?.storelistData} />
        );

      case "customer did not pickup":
        return (
          <CancelOrder data={props.data} storelistData={props?.storelistData} />
        )

      case "order removed":
        return (
          <CancelOrder data={props.data} storelistData={props?.storelistData} />
        );
        
      case "order_returned":
        return (
          <CancelOrder data={props.data} storelistData={props?.storelistData} />
        )
       
      

      case "order accepted":
        return (
          <Inprogress
            // robotSpawn={robotSpawn}
            storelistData={props?.storelistData}
            canel={props.canel}
            setcancel={props.setcancel}
            data={props.data}
            refreshfunction={props.refreshfunction}
          />
        );
      case "order delivered":
        return (
          <AcceptSuccess
            data={props.data}
            storelistData={props?.storelistData}
          />
        );
      case "order received":
        return (
          <Inprogress
            storelistData={props?.storelistData}
            canel={props.canel}
            setcancel={props.setcancel}
            data={props.data}
            refreshfunction={props.refreshfunction}
          />
        );
      case "New":
        return (
          <NewOrder
            // robotSpawn={robotSpawn}
            setloaderstate={props.setloaderstate}
            storelistData={props?.storelistData}
            canel={props.canel}
            setcancel={props.setcancel}
            data={props.data}
            refreshfunction={props.refreshfunction}
            handleClosetwo={props.handleClosetwo}
          />
        );
      case "order confirmed":
        return (
          <NewOrder
            // robotSpawn={robotSpawn}
            setloaderstate={props.setloaderstate}
            storelistData={props?.storelistData}
            canel={props.canel}
            setcancel={props.setcancel}
            data={props.data}
            refreshfunction={props.refreshfunction}
            handleClosetwo={props.handleClosetwo}
          />
        );
      case "booked":
        return (
          <Booked
            // robotSpawn={robotSpawn}
            storelistData={props?.storelistData}
            canel={props.canel}
            setcancel={props.setcancel}
            data={props.data}
            refreshfunction={props.refreshfunction}
          />
        );
      default:
        return (
          <Inprogress
            storelistData={props?.storelistData}
            canel={props.canel}
            setcancel={props.setcancel}
            data={props.data}
            refreshfunction={props.refreshfunction}
          />
        );
    }
  };
  return <>{getcontent(props?.data?.status)}</>;
}

export default TabPanelInner;
