import { XMLNode } from '../xml-node';
import { ALIGNMENT } from '../buffer-builder';
export default class AlignNode extends XMLNode {
    constructor(node) {
        super(node);
    }
    open(bufferBuilder) {
        switch (this.attributes.mode) {
            case 'center':
                bufferBuilder.startAlign(ALIGNMENT.CENTER);
                break;
            case 'left':
                bufferBuilder.startAlign(ALIGNMENT.LEFT);
                break;
            case 'right':
                bufferBuilder.startAlign(ALIGNMENT.RIGHT);
                break;
        }
        return bufferBuilder;
    }
    close(bufferBuilder) {
        bufferBuilder.resetAlign();
        return bufferBuilder;
    }
}
