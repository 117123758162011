import React from 'react'
// import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
// import { Button } from '@mui/material';
// import AdminHeader from '../AdminHeader';
import RightContent from '../RightContent';
function AcceptSuccess(props) {

  return (

    <>
      <div className="AdminDashboard-tabs-rightside-inner-wrapper"> 
       <div className='AdminDashboard-tabs-rightside-header'> 
            <h5 className="rightsideheader-h5">  {props.data.orderId}  <span> , {props.data.storeId}</span>
            </h5>
            <p className='dashboard-tab-delivered rightsideheader-p'> {props.data.status}</p>
       </div>
    <div className='AdminDashboard-tabs-rightside-content'> 
         <RightContent data={props.data} delivered={true} storelistData={props?.storelistData}/>
         {/* <div className="AdminDashboard-tabs-rightside-content-right-side"> 
            <Button className='AdminDashboard-tabs-rightside-content-right-side-btn active-btndashboard'>  Cancel Order</Button>
            <Button className='AdminDashboard-tabs-rightside-content-right-side-btn' disabled> Summon Robot</Button>
            <Button className='AdminDashboard-tabs-rightside-content-right-side-btn' disabled> Open Door</Button>
        </div>  */}
    </div>
    {/* <div className="Dashboard-accept-order-button-wrapper-cancel">
      <Button className="Dashboard-accept-order-button-none" disabled> Cancel with refund</Button>
      <Button className="Dashboard-accept-order-button-none" disabled> Cancel Order</Button>
    </div> */}
 </div>
</>
  )
}

export default AcceptSuccess