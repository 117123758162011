import parser from 'xml-parser';
import { BufferBuilder } from './buffer-builder';
import { NodeFactory } from './node-factory';
export class XMLParser {
    parser(xml) {
        let parsedXML = parser(xml);
        return this.compile(parsedXML);
    }
    compile(parsedXML) {
        let bufferBuilder = new BufferBuilder();
        let rootNode = this.adapter(parsedXML.root, null);
        return rootNode.draw(bufferBuilder);
    }
    adapter(node, parentNode) {
        let xmlNode = NodeFactory.create(node.name, node);
        if (parentNode)
            parentNode.addChild(xmlNode);
        if (node.children.length > 0) {
            node.children.forEach(child => {
                this.adapter(child, xmlNode);
            });
        }
        return xmlNode;
    }
}
