import { Box, Modal, Stack } from "@mui/material";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = ({ handleClose }) => {
  const LoaderStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    bgcolor: "transparent",
    borderRadius: 2,
    p: 4,
  };
  return (
    <div>
      <Modal
        open={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={LoaderStyle}>
          <Stack
            sx={{ color: "grey.500", justifyContent: "center" }}
            spacing={2}
            direction="row"
          >
            <CircularProgress color="inherit" />
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default Loader;
