import { Box, Modal } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { MdCloudUpload } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { BiCategoryAlt } from "react-icons/bi";
import { MdOutlineDescription } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  askuploadtype,
  editProduct,
  getproductlist,
  getSiteDetails,
  updateimagetoCdn,
} from "../../API";
import * as yup from "yup";
import Loader from "../../components/Popups/Loader";
import { IoIosAdd, IoIosClose } from "react-icons/io";
import { Transition, Menu } from "@headlessui/react";
import { SlArrowDown } from "react-icons/sl";
//   import Loader from "./Loader";

const editProductModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 50px)",
  height: "90%",
  bgcolor: "#ffffff",
  // boxShadow: 24,
  border: "none",
  zIndex: "100",
  outline: "none",
  overflow: "auto",
  borderRadius: "12px",
};

const EditProduct = ({
  openEditProductModal,
  setOpenEditProductModal,
  selectedProduct,
  storeData,
  setproductlist,
  allergens,
}) => {
  // console.log("SELECTED PRODUCT", selectedProduct);
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [imageAsset, setImageAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tempIngredients, setTempIngredients] = useState([]);
  const [siteDetails, setSiteDetails] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState();

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const uploadImage = (e) => {
    // console.log("UPLOAD IMAGE EVENT", e);
    let file = e.target.files[0];
    setFile(file);
    // console.log(file, "FILE");
    const reader = new FileReader();
    let url = reader.readAsDataURL(file);
    // console.log(url);

    reader.onloadend = function (e) {
      setImageAsset(reader.result);
      // console.log("RESULT", reader.result);
    };
  };

  const validationSchema = yup.object({
    productName: yup.string().required("Product Name is required."),
    // spanishProductName: yup.string().required("Product Name is required."),
    productPrice: yup.string().required("Product Price is required."),
    productDescription: yup.string("Product Description is required."),
    // spanishProductDescription: yup.string("Product Description is required."),
    productCategory: yup.string("Product Category is required."),
    productImage: yup.string("Product Image is required."),
    // ingredients: yup.string(),
    // positionYaw: yup.string(),
    // status: yup.boolean(),
    // imageLocationUrl: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      productName: selectedProduct?.name[selectedLanguage?.code]
        ? selectedProduct?.name[selectedLanguage?.code]
        : "",
      productPrice: selectedProduct?.price ? selectedProduct.price : "",
      productDescription: selectedProduct?.description[selectedLanguage?.code]
        ? selectedProduct?.description[selectedLanguage?.code]
        : "",
      productCategory: selectedProduct?.category[selectedLanguage?.code]
        ? selectedProduct?.category[selectedLanguage?.code]
        : "",
      productImage: selectedProduct?.image ? selectedProduct.image : "",
      ingredients: selectedProduct?.ingredients
        ? selectedProduct.ingredients
        : [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // console.log(values, "FORM VALUES");
      if (imageAsset) {
        // let formData = new FormData();
        // formData.append("file", file);
        // console.log("FILE Before", file)
        let bodyFormData = new FormData();

        askuploadtype(file.type, token)
          .then((result) => {
            bodyFormData.append("file", file);
            // console.log(result.data.data.uploadURL, "ASDK RESULT");
            updateimagetoCdn(result.data.data.uploadURL, bodyFormData, token)
              .then((res) => {
                // console.log(res.data.result);
                values.productImage = res.data.result.variants[0];
                editProduct(
                  selectedProduct.productId,
                  values,
                  selectedProduct.storeId,
                  token,
                  selectedLanguage
                )
                  .then((res) => {
                    setLoader(false);
                    setOpenEditProductModal(false);
                    toast.success("Product updated successfully!");
                    // navigate(`/items/${selectedProduct.storeId}`);
                    resetForm({ values: "" });
                    getproductlist(selectedProduct.storeId)
                      .then((res) => {
                        // console.log('Product list response',res)
                        setproductlist(res.data.message);
                        setImageAsset(null);
                        // formik.setValues("productImage", selectedProduct?.image ? selectedProduct.image : "")
                        // navigate(`/items/${selectedProduct.storeId}`)
                        // window.location.reload(false);
                      })
                      .catch((err) => {
                        console.log("Error in Product list", err);
                      });
                  })
                  .catch((err) => {
                    setLoader(false);
                    setOpenEditProductModal(false);
                    console.error(err);
                    toast.error(err.message);
                    // toast.error("Something went wrong while editing location!");
                  });
                setLoader(true);
              })
              .catch((err) => {
                console.log("Error in Image to CDN Upload", err);
              });
          })
          .catch((err) => {
            console.log("Error in Ask Image Upload", err);
          });
        setLoader(true);
      } else {
        editProduct(
          selectedProduct.productId,
          values,
          selectedProduct.storeId,
          token,
          selectedLanguage
        )
          .then((res) => {
            setLoader(false);
            setOpenEditProductModal(false);
            toast.success("Product updated successfully!");
            getproductlist(storeData?.state?.storeId)
              .then((res) => {
                // console.log('Product list response',res)
                setproductlist(res.data.message);
                // setImageAsset(null)
                // formik.setValues("productImage", selectedProduct?.image ? selectedProduct.image : "")
                // window.location.reload(false);
                // navigate(`/items/${selectedProduct.storeId}`)
              })
              .catch((err) => {
                console.log("Error in Product list", err);
              });
            resetForm({ values: "" });
          })
          .catch((err) => {
            setLoader(false);
            setOpenEditProductModal(false);
            console.error(err);
            toast.error(err.message);
            // toast.error("Something went wrong while editing location!");
          });
      }

      setLoader(true);
    },
  });

  useEffect(() => {
    if (selectedProduct && selectedLanguage) {
      formik.setValues({
        productName: selectedProduct?.name[selectedLanguage?.code]
          ? selectedProduct?.name[selectedLanguage?.code]
          : "",
        productPrice: selectedProduct?.price ? selectedProduct.price : "",
        productDescription: selectedProduct?.description[selectedLanguage?.code]
          ? selectedProduct?.description[selectedLanguage?.code]
          : "",
        productCategory: selectedProduct?.category[selectedLanguage?.code]
          ? selectedProduct?.category[selectedLanguage?.code]
          : "",
        productImage: selectedProduct?.image ? selectedProduct.image : "",
        ingredients: selectedProduct?.ingredients
          ? selectedProduct.ingredients
          : [],
      });
      // setTempIngredients(selectedProduct?.ingredients
      //   ? selectedProduct.ingredients
      //   : [])
    }
    if (openEditProductModal) {
      // console.log("openEditProductModal", openEditProductModal)
      // console.log("selectedProduct", selectedProduct)

      formik.setFieldValue({
        productImage: selectedProduct?.image ? selectedProduct.image : "",
      });
    }
  }, [selectedProduct, openEditProductModal, selectedLanguage]);

  useEffect(() => {
    getSiteDetails(process.env.REACT_APP_SITE_NAME)
      .then((res) => {
        setSiteDetails(res.data.message);
        setSelectedLanguage(res.data.message.languages[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Modal open={openEditProductModal} backdrop="static" keyboard="false">
      <Box sx={{ ...editProductModalStyle }}>
        <div className="flex flex-col items-center justify-center w-full">
          {/* <div className="flex items-center h-10 intro-y">
            <h2 className="mr-5 text-3xl font-extrabold truncate">
              Update Product
            </h2>
          </div> */}
          {isLoading ? (
            <div className="flex flex-col items-center justify-center p-4">
              <Loader />
              {/* <span className="text-white">Updating Product...</span> */}
            </div>
          ) : (
            <div className="flex items-center justify-center w-full">
              <span
                className="absolute z-10 w-5 h-5 text-red-500 cursor-pointer top-3 right-3"
                onClick={() => {
                  setOpenEditProductModal(false);
                  formik.setFieldValue(
                    "productImage",
                    selectedProduct?.image ? selectedProduct.image : ""
                  );
                  // formik.setFieldValue("ingredients", selectedProduct.ingredients)
                  // console.log(formik.values.ingredients)
                  // setTempIngredients(formik.values.ingredients)
                }}
              >
                <AiOutlineClose className="w-full h-full" />
              </span>
              <div className="w-full h-full transition duration-300 transform bg-white">
                <div className="flex flex-col items-center justify-center w-full h-full gap-4 p-5 pt-3 pb-0 rounded-lg">
                  <div className="flex items-center h-10 intro-y">
                    <h2 className="mr-5 text-3xl font-extrabold truncate">
                      Edit Product
                    </h2>
                  </div>
                  <form
                    onSubmit={formik.handleSubmit}
                    id="editForm"
                    // encType="multipart/form-data"
                    className="w-full h-full space-y-4"
                  >
                    <div>
                      <h6 className="text-gray-500">
                        Name <span className="text-red-500">*</span>
                      </h6>
                      <div className="relative flex gap-2">
                        <span className="absolute left-0 top-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            ></path>
                          </svg>
                        </span>
                        <input
                          className="w-full py-2 pl-6 text-sm text-gray-600 placeholder-gray-400 border-b-2 border-gray-300 invalid:border-red-500 focus:border-blue-300 focus:outline-none"
                          type="text"
                          placeholder="Product Name"
                          name="productName"
                          value={formik.values.productName}
                          
                          onChange={formik.handleChange}
                          required
                        />
                        {siteDetails && (
                          <div className="flex items-center justify-center">
                            <div className="flex flex-col justify-center gap-1">
                              <Menu
                                as="div"
                                className="relative p-2 px-3 text-xs text-white rounded-md bg-ottonomyBlue"
                              >
                                <div>
                                  <Menu.Button className="flex justify-between w-full h-auto pb-1 border-b focus:outline-none border-b-white">
                                    {selectedLanguage?.name}
                                    <SlArrowDown className="mt-1 ml-2 font-semibold text-white" />
                                  </Menu.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    className={`absolute right-0 z-10 w-full ${
                                      siteDetails?.languages?.length > 4 &&
                                      "overflow-y-scroll h-40"
                                    } mt-[0.70rem] origin-top-right bg-transparent border-2 !border-[#00B7D4] divide-y divide-gray-100 rounded-lg focus:outline-none`}
                                  >
                                    <div className="w-full">
                                      {siteDetails?.languages?.map((language, index) => {
                                        return (
                                          <Menu.Item
                                            key={"distribution__no_" + index}
                                            value={language?.name}
                                          >
                                            {({ active }) => (
                                              <button
                                                onClick={() =>
                                                  setSelectedLanguage(language)
                                                }
                                                type="button"
                                                className={`${
                                                  active && ""
                                                } group flex w-full px-2 items-center justify-start rounded-md py-2 text-xs text-ottonomyBlue hover:text-white hover:bg-ottonomyBlue`}
                                              >
                                                <span className="text-left line-clamp-1">
                                                  {language?.name}
                                                </span>
                                              </button>
                                            )}
                                          </Menu.Item>
                                        );
                                      })}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <h6 className="text-gray-500">
                        Category {" "}
                        <span className="text-red-500 ">*</span>
                      </h6>
                      <div className="relative">
                        <span className="absolute left-0 top-2">
                          <BiCategoryAlt className="w-5 h-5 text-gray-400" />
                        </span>
                        <input
                          className="w-full py-2 pl-6 text-sm text-gray-600 placeholder-gray-400 border-b-2 border-gray-300 invalid:border-red-500 focus:border-blue-300 focus:outline-none"
                          type="text"
                          placeholder="Category"
                          name="productCategory"
                          value={formik.values.productCategory}
                          onChange={formik.handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <h6 className="text-gray-500">Description </h6>
                      <div className="relative">
                        <span className="absolute left-0 top-2">
                          <MdOutlineDescription className="w-5 h-5 text-gray-400" />
                        </span>
                        <textarea
                          className="h-6 min-h-[6rem] w-full border-b-2 border-gray-300 py-2 pl-6 text-gray-600
                        placeholder-gray-400 invalid:border-red-500 focus:border-blue-300 focus:outline-none text-sm"
                          type="text"
                          placeholder="Product Description"
                          name="productDescription"
                          value={formik.values.productDescription}
                          onChange={formik.handleChange}
                        ></textarea>
                      </div>
                    </div>

                    <div>
                      <h6 className="text-gray-500">
                        Price <span className="text-red-500">*</span>
                      </h6>
                      <div className="relative">
                        <span className="absolute left-0 top-2">
                          <span className="flex items-center justify-center w-5 h-5 text-gray-400">
                            {storeData?.state?.currency?.symbol}
                          </span>
                          {/* <BsCurrencyEuro className="w-5 h-5 text-gray-400" /> */}
                        </span>
                        <input
                          className="w-full py-2 pl-6 text-sm text-gray-600 placeholder-gray-400 border-b-2 border-gray-300 invalid:border-red-500 focus:border-blue-300 focus:outline-none"
                          type="number"
                          placeholder="Product Price"
                          name="productPrice"
                          value={formik.values.productPrice}
                          onChange={formik.handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <h6 className="text-gray-500">Allergens</h6>
                      <div className="relative">
                        {/* <span className="absolute left-0 top-2">
                        <GiSwapBag className="w-5 h-5 text-gray-400" />
                      </span> */}

                        <div className="flex flex-wrap w-full gap-1 py-2">
                          <>
                            {allergens?.map((ingredient, index) => {
                              if (
                                formik?.values?.ingredients?.find(
                                  (match) => match.ingredient._id === ingredient._id
                                )
                              ) {
                                return (
                                  <span
                                    key={`ingredient__no__${index}`}
                                    className="relative w-8 h-8 border-[2px] border-gray-300 rounded-full"
                                    title={ingredient.name}
                                  >
                                    <span
                                      className="absolute text-white bg-[rgba(0,0,0,0.4)] rounded-full cursor-pointer -top-1 -right-2"
                                      onClick={() => {
                                        formik.values.ingredients =
                                          formik.values.ingredients.filter(
                                            (match) =>
                                              match.ingredient._id !== ingredient._id
                                          );
                                      }}
                                    >
                                      <IoIosClose className="w-4 h-4" />
                                    </span>
                                    <img
                                      src={ingredient.image}
                                      alt={`ingredient__img__${index}`}
                                    />
                                  </span>
                                );
                              } else {
                                return (
                                  <span
                                    key={`ingredient__no__${index}`}
                                    className="relative w-8 h-8 border-[2px] border-gray-300 rounded-full cursor-pointer"
                                    onClick={() => {
                                      formik.values.ingredients.push({
                                          ingredient: ingredient,
                                          name: {
                                            [selectedLanguage.code]: ingredient.name
                                          },
                                          _id: ingredient._id
                                        }
                                      );
                                    }}
                                    title={ingredient.name}
                                  >
                                    {/* <span
                                    className="absolute text-white bg-[rgba(0,0,0,0.4)] rounded-full cursor-pointer -top-1 -right-2"
                                    onClick={() => {
                                      formik.values.ingredients.splice(
                                        index,
                                        1
                                      );
                                    }}
                                  >
                                    <IoIosClose className="w-4 h-4" />
                                  </span> */}
                                    <img
                                      src={ingredient.image}
                                      alt={`ingredient__img__${index}`}
                                      className="opacity-50 grayscale"
                                    />
                                  </span>
                                );
                              }
                            })}
                            {/* <span className="flex justify-center items-center w-8 h-8 border-[2px] border-gray-400 rounded-full">
                              <IoIosAdd className="w-6 h-6 text-gray-500" />
                            </span> */}
                          </>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h6 className="text-gray-500">Image</h6>
                      <div className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dotted rounded-lg cursor-pointer group">
                        <>
                          {!imageAsset && formik.values.productImage === "" ? (
                            <>
                              <label className="flex flex-col items-center justify-center w-full h-full cursor-pointer min-h-[150px]">
                                <div className="flex flex-col items-center justify-center w-full h-full">
                                  <MdCloudUpload className="text-3xl text-gray-500 hover:!text-ottonomyBlue" />
                                  <p className="text-gray-500 hover:text-ottonomyBlue">
                                    Click here to upload
                                  </p>
                                </div>
                                <input
                                  type="file"
                                  name="uploadImage"
                                  accept="image/png, image/jpg, image/jpeg, image/webp, image/gif, .svg"
                                  onChange={uploadImage}
                                  className="hidden"
                                />
                              </label>
                            </>
                          ) : (
                            <>
                              <div className="relative h-full p-3">
                                <img
                                  src={
                                    imageAsset
                                      ? imageAsset
                                      : formik.values.productImage !== ""
                                      ? selectedProduct.image
                                      : imageAsset
                                  }
                                  alt="uploadedImage"
                                  className="object-cover w-full h-full"
                                />
                                <button
                                  type="button"
                                  className="absolute p-2 text-xl transition-all duration-500 ease-in-out bg-red-500 rounded-full outline-none cursor-pointer bottom-2 right-2 hover:shadow-md"
                                  onClick={() => {
                                    setImageAsset(null);
                                    if (formik.values.productImage !== "") {
                                      formik.setFieldValue("productImage", "");
                                    }
                                    // console.log(
                                    //   formik.values.productImage,
                                    //   "IMAGE URL"
                                    // );
                                    setFile(null);
                                  }}
                                >
                                  <MdDelete className="!text-white fill-white" />
                                </button>
                              </div>
                            </>
                          )}
                        </>
                      </div>
                    </div>

                    <button
                      type="submit"
                      // disabled={isLoading ? true : false}
                      className="flex items-center justify-center px-4 py-2 mx-auto my-4 space-x-2 font-semibold tracking-widest text-center text-white uppercase transition-colors duration-300 ease-in-out rounded shadow-lg w-max bg-ottonomyBlue hover:bg-primaryDarkShade"
                    >
                      {/* {isLoading ? (
                        <div className="flex items-center justify-start w-full h-auto space-x-1">
                          <div
                            className="w-1 h-1 rounded-full animate-bounce bg-white/90"
                            style={{ animationDelay: "0.2s" }}
                          ></div>
                          <div
                            className="w-1 h-1 rounded-full animate-bounce bg-white/90"
                            style={{ animationDelay: "0.4s" }}
                          ></div>
                          <div
                            className="w-1 h-1 rounded-full animate-bounce bg-white/90"
                            style={{ animationDelay: "0.6s" }}
                          ></div>
                        </div>
                      ) : ( */}
                      <span>Update</span>
                      {/* )} */}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default EditProduct;
