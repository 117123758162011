import { Link } from 'react-router-dom';
import { Box, Modal } from "@mui/material";
import { useFormik } from "formik";
import { Fragment, useState, useEffect } from "react";
import { MdOutlineDescription } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { Menu, Transition } from "@headlessui/react";
import { SlArrowDown } from "react-icons/sl";
import Loader from "../../components/Popups/Loader";
import { MdDelete } from "react-icons/md";
import { MdCloudUpload } from "react-icons/md";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import React, { useRef } from "react";
import * as yup from "yup";
import { ChromePicker, SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import { TagsInput } from "react-tag-input-component";


import {
  askuploadtype,
  editStore,
  editstore,
  getSiteDetails,
  getstorelist,
  updateimagetoCdn,
  editsite,
} from "../../API";


const editProductModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 50px)",
  height: "90%",
  bgcolor: "#ffffff",
  // boxShadow: 24,
  border: "none",
  zIndex: "100",
  outline: "none",
  overflow: "auto",
  borderRadius: "12px",
};




const EditSites = ({
  openSiteEditModal,
  selectedStore,
  setOpenSiteEditModal,
  setstorelist,
}) => {

  const [loader, setLoader] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [siteDetails, setSiteDetails] = useState();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageAsset, setImageAsset] = useState(null);
  const [favAsset, setFaviconAsset] = useState(null);
  const [selected, setSelected] = useState([]);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState("#00b7d4");

  const colorPickerRef = useRef(null);



  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    console.log("Closed")
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    setColor(newColor.hex);
  };

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });



  useEffect(() => {

    // const handleClickOutside = (event) => {
    //   if(colorPickerRef.current && !colorPickerRef.current.contains(event.target)){
    //     handleClose();
    //   }
    // };
    // document.addEventListener("mousedown", handleClickOutside);
    // return () => {
    //   // Clean up event listener on component unmount
    //   document.removeEventListener("mousedown", handleClickOutside);
    // };


    const faviconUrl = siteDetails?.favicon;
    if (faviconUrl) {
      // Fetch the favicon image using the URL
      fetch(faviconUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Convert the blob to a blob URL
          const faviconBlobUrl = URL.createObjectURL(blob);
          // Set the favicon asset
          setFaviconAsset(faviconBlobUrl);
        })
        .catch((error) => {
          console.log("Error fetching favicon:", error);
        });
    }
    // if(siteDetails){
    //  const faviconUrl = siteDetails.favicon;
    //  fetch(faviconUrl)
    //  .then((response)=> response.blob())
    //  .then((blob)=>{
    //   const faviconBlobUrl = URL.createObjectURL(blob);
    //   setFaviconAsset(faviconBlobUrl);
    //  })
    //  .catch((error)=>{
    //   console.log("Error fetching favicon:", error);
    //  })
    // }


    getSiteDetails(process.env.REACT_APP_SITE_NAME)
      .then((res) => {
        console.log(res.data)
        setSiteDetails(res.data.message);
        // setSelectedLanguage(res.data.message.languages[0]);
        const { appLogo, primaryColor, languages } = res?.data?.message;
        setSelectedLanguage(languages[0]);
        setSelected(languages.map(language => language.name));

        setImageAsset(appLogo);
        setColor(primaryColor);

        const { favicon } = res?.data?.message;
        // const blob = new Blob([favicon.data]);

        // Convert the Blob to a data URL
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          setFaviconAsset(dataUrl);
        };
        //  reader.readAsDataURL(blob);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const validationSchema = yup.object({
    siteName: yup.string().required("Site Name is required."),
    // spanishProductName: yup.string().required("Product Name is required."),
    //productPrice: yup.string().required("Product Price is required."),
    storeDescription: yup.string("Store Description is required."),
    storeImage: yup.string("Store Image is required."),
    storeOwner: yup.string("Store Owner is required."),
  });
  const token = localStorage.getItem("token");

  const formik = useFormik({
    initialValues: {
      siteName: siteDetails?.siteName ? siteDetails.siteName : " ",
      // productPrice: selectedStore?.price ? selectedStore.price : "",
      storeName: selectedStore?.name ? selectedStore.name : "",
      storeappLogo: siteDetails?.appLogo ? siteDetails.appLogo : "",

      primaryColor: siteDetails?.primaryColor ? siteDetails.primaryColor : "",
      storeDescription: selectedStore?.description
        ? selectedStore.description
        : "",

      storeOwner: selectedStore?.storeManager?.name
        ? selectedStore?.storeManager?.name
        : "",

      storeImage: selectedStore?.image ? selectedStore.image : "",
      storefavicon: siteDetails?.favicon ? siteDetails.favicon : "",
      // storeappLogo: selectedStore?.appLogo ? selectedStore.appLogo : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log("submit");
      if (false) {


        let bodyFormData = new FormData();

        askuploadtype(file.type, token)
          .then((result) => {
            bodyFormData.append("file", file);
            // console.log(result.data.data.uploadURL, "ASDK RESULT");
            updateimagetoCdn(result.data.data.uploadURL, bodyFormData, token)
              .then((res) => {
                // console.log(res.data.result);
                values.productImage = res.data.result.variants[0];
                editsite(
                  siteDetails.siteName,
                  values,
                  token,
                  selectedLanguage
                )
                  .then((res) => {
                    setLoader(false);
                    setOpenSiteEditModal(false);
                    toast.success("Product updated successfully!");

                    resetForm({ values: "" });
                    getstorelist(selectedStore.storeId)
                      .then((res) => {
                        // console.log('Product list response',res)
                        setstorelist(res.data.message);
                        setImageAsset(null);

                        setFaviconAsset(null);

                      })
                      .catch((err) => {
                        console.log("Error in Product list", err);
                      });
                  })
                  .catch((err) => {
                    setLoader(false);
                    setOpenSiteEditModal(false);
                    console.error(err);
                    toast.error(err.message);
                    // toast.error("Something went wrong while editing location!");
                  });
                setLoader(true);
              })
              .catch((err) => {
                console.log("Error in Image to CDN Upload", err);
              });
          })
          .catch((err) => {
            console.log("Error in Ask Image Upload", err);
          });
        setLoader(true);
      } else {
        editsite(siteDetails.siteName,values, token, selectedLanguage)
          .then((res) => {
            setLoader(false);
            setOpenSiteEditModal(false);
            toast.success("Store updated successfully!");
            // getstorelist(selectedStore.storeId)
            //   .then((res) => {

            //     // console.log('Product list response',res)
            //     setstorelist(res.data.message);


            //   })
            //   .catch((err) => {
            //     console.log("Error in Product list", err);
            //   });
            resetForm({ values: "" });
          })
          .catch((err) => {
            setLoader(false);
            setOpenSiteEditModal(false);
            console.error(err);
            toast.error(err.message);
            // toast.error("Something went wrong while editing location!");
          });
      }
    },
  });

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
    // You may want to update your formik values here
  };

  const uploadImage = (e) => {
    // console.log("UPLOAD IMAGE EVENT", e);
    let file = e.target.files[0];
    setFile(file);

    //  console.log(file, "FILE");
    const reader = new FileReader();
    let url = reader.readAsDataURL(file);

    // console.log(url);

    reader.onloadend = function (e) {
      setImageAsset(reader.result);
      setFaviconAsset(reader.result);
      // console.log("RESULT", reader.result);
    };
  };




  useEffect(() => {
    // if (selectedStore && selectedLanguage) {
      formik.setValues({
        siteName: siteDetails?.siteName ? siteDetails.siteName : " ",
        primaryColor:color
      // storefavicon: siteDetails?.favicon ? siteDetails.favicon : "",
    });

    if (openSiteEditModal) {


      formik.setFieldValue({
        storeImage: selectedStore?.image ? selectedStore.image : "",
        // storefavicon: selectedStore?.favicon ? selectedStore.favicon : "",
      });
    }
  }, [selectedStore, openSiteEditModal, siteDetails,color]);

  return (
    <Modal open={openSiteEditModal} backdrop="static" keyboard="false">
      <Box sx={{ ...editProductModalStyle }}>
        <span
          className="absolute z-10 w-5 h-5 text-red-500 cursor-pointer top-3 right-3"
          onClick={() => {
            setOpenSiteEditModal(false);
            formik.setFieldValue(
              "storeImage",
              selectedStore?.image ? selectedStore.image : ""
            );

          }}
        >
          <AiOutlineClose className="w-full h-full" />
        </span>

        <div className="w-full h-full transition duration-300 transform bg-white">
          <div className="flex flex-col items-center justify-center w-full h-full gap-4 p-5 pt-3 pb-0 rounded-lg">
            <div className="flex items-center h-10 intro-y">
              <h2 className="mr-5 text-3xl font-extrabold truncate">
                Settings
              </h2>
            </div>
            <form 
              onSubmit={formik.handleSubmit}
              // encType="multipart/form-data"
              className="w-full h-full space-y-4"
            >
              <div>
                <h6 className="text-gray-500">
                  SiteName  <span className="text-red-500">*</span>
                </h6>
                <div className="relative flex gap-2">
                  <span className="absolute left-0 top-2">

                  </span>
                  <input
                    className="w-full py-2 pl-2 text-sm text-gray-600 placeholder-gray-400 border-b-2 border-gray-300 invalid:border-red-500 focus:border-blue-300 focus:outline-none"
                    type="text"
                    placeholder="siteName"
                    name="siteName"
                    // value={formik.values.storeName}
                    value={
                      formik?.values?.siteName
                    }
                    onChange={formik.handleChange}
                    required
                  />
                  {siteDetails && (
                    <div className="flex items-center justify-center">
                      <div className="flex flex-col justify-center gap-1">
                        <Menu
                          as="div"

                        >

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              className={`absolute right-0 z-10 w-full ${siteDetails?.languages?.length > 4 &&
                                "overflow-y-scroll h-40"
                                } mt-[0.70rem] origin-top-right bg-transparent border-2 !border-[#00B7D4] divide-y divide-gray-100 rounded-lg focus:outline-none`}
                            >
                              <div className="w-full">
                                {siteDetails?.languages?.map(
                                  (language, index) => {
                                    return (
                                      <Menu.Item
                                        key={"distribution__no_" + index}
                                        value={language?.name}
                                      >
                                        {({ active }) => (
                                          <button
                                            onClick={() =>
                                              setSelectedLanguage(
                                                language
                                              )
                                            }
                                            type="button"
                                            className={`${active && ""
                                              } group flex w-full px-2 items-center justify-start rounded-md py-2 text-xs text-ottonomyBlue hover:text-white hover:bg-ottonomyBlue`}
                                          >
                                            <span className="text-left line-clamp-1">
                                              {language?.name}
                                            </span>
                                          </button>
                                        )}
                                      </Menu.Item>
                                    );
                                  }
                                )}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>

                <h6 className="text-gray-500" onClick={() => handleClose()}>Primary Color</h6>
                {/* <ChromePicker
                  color={color}
                  onChange={handleColorChange}
                /> */}
               
                <div className="flex items-center gap-2 mt-[4px]">
                  <div className='primaryColorBox w-[100px] h-[30px] box-border border-4' style={{
                    backgroundColor: `${color}`,
                    border: "2px solid white",
                  }} onClick={handleClick}></div>
                  <p style={{ color: `${color}` }}>{color}</p></div>
                 
                {displayColorPicker ? (
                  <div>
                    <SketchPicker className='fixed' color={color} onChange={handleChange} />
                  // </div>
                ) : null}

              </div>

              <div>
                <h6 className="text-gray-500" onClick={()=> handleClose()}>App Logo</h6>
                <div className="flex flex-col items-center justify-center w-full h-400 border-2 border-gray-300 border-dotted rounded-lg cursor-pointer group">
                  <>
                    {!imageAsset && formik.values.storeImage === "" ? (
                      <>
                        <label className="flex flex-col items-center justify-center w-full h-full cursor-pointer min-h-[150px]">
                          <div className="flex flex-col items-center justify-center w-full h-full">
                            <MdCloudUpload className="text-3xl text-gray-500 hover:!text-ottonomyBlue" />
                            <p className="text-gray-500 hover:text-ottonomyBlue">
                              Click here to upload
                            </p>
                          </div>
                          <input
                            type="file"
                            name="uploadImage"
                            accept="image/png, image/jpg, image/jpeg, image/webp, image/gif, .svg"
                            onChange={uploadImage}
                            className="hidden"
                          />
                        </label>
                      </>
                    ) : (
                      <>
                        <div className="relative h-full p-3">
                          <button
                            type="button"
                            className="absolute p-2 text-xl transition-all duration-500 ease-in-out bg-red-500 rounded-full outline-none cursor-pointer bottom-2 left-48 hover:shadow-md"
                            onClick={() => {
                              setImageAsset(null);
                              if (formik.values.storeImage !== "") {
                                formik.setFieldValue("storeImage", "");
                              }

                              setFile(null);
                            }}
                          >
                            <MdDelete className="!text-white fill-white" />
                          </button>
                        </div>
                      </>
                    )}
                  </>
                </div>
              </div>


              <div>
                <h6 className="text-gray-500">Favicon</h6>
                <div className="flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dotted rounded-lg cursor-pointer group">
                  <>
                    {!favAsset && formik.values.storeImage === "" ? (
                      <>
                        <label className="flex flex-col items-center justify-center w-full h-full cursor-pointer min-h-[150px]">
                          <div className="flex flex-col items-center justify-center w-full h-full">
                            <MdCloudUpload className="text-3xl text-gray-500 hover:!text-ottonomyBlue" />
                            <p className="text-gray-500 hover:text-ottonomyBlue">
                              Click here to upload
                            </p>
                          </div>
                          <input
                            type="file"
                            name="uploadImage"
                            accept="image/png, image/jpg, image/jpeg, image/webp, image/gif, .svg"
                            onChange={uploadImage}
                            className="hidden"
                          />
                        </label>
                      </>
                    ) : (
                      <>
                        <div className="relative h-full p-3">
                          <img
                            src={
                              `${process.env.REACT_APP_ORDERAT}/site/${process.env.REACT_APP_SITE_NAME}/getFavicon`
                            }
                            alt="uploadedImage"
                            className="object-cover w-full h-full"
                          />
                          <button
                            type="button"
                            className="absolute p-2 text-xl transition-all duration-500 ease-in-out bg-red-500 rounded-full outline-none cursor-pointer bottom-2 left-20 hover:shadow-md"
                            onClick={() => {
                              setFaviconAsset(null);
                              if (formik.values.storeImage !== "") {
                                formik.setFieldValue("storeImage", "");
                              }

                              setFile(null);
                            }}
                          >
                            <MdDelete className="!text-white fill-white" />
                          </button>
                        </div>
                      </>
                    )}
                  </>
                </div>
              </div>

              <div>
                <h1 className='font-poppins text-base text-gray-500'>Languages</h1>
                <TagsInput
                  value={selected}
                  onChange={setSelected}
                  name="languages"
                  placeHolder="enter languages"
                />
              </div>



              <button
                type="submit"
                // disabled={isLoading ? true : false}
                className="flex items-center justify-center px-4 py-2 mx-auto my-4 space-x-2 font-semibold tracking-widest text-center text-white uppercase transition-colors duration-300 ease-in-out rounded shadow-lg w-max bg-ottonomyBlue hover:bg-primaryDarkShade"
              >

                <span>Update</span>
                {/* )}  */}
              </button>
            </form>
          </div>
        </div>

      </Box>
    </Modal>
  );
}
export default EditSites;


// import React from 'react'

// const EditSites = () => {
//   return (
//     <div>EditSites</div>
//   )
// }

// export default EditSites
