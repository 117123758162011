import { XMLNode } from '../xml-node';
import { UNDERLINE_MODE } from '../buffer-builder';
export default class UnderlineNode extends XMLNode {
    constructor(node) {
        super(node);
    }
    open(bufferBuilder) {
        switch (this.attributes.mode) {
            case 'one-point':
                bufferBuilder.startUnderline(UNDERLINE_MODE.ONE_POINT_OF_COARSE);
                break;
            case 'two-points':
                bufferBuilder.startUnderline(UNDERLINE_MODE.TWO_POINTS_OF_COARSE);
                break;
            default:
                bufferBuilder.startUnderline();
        }
        return bufferBuilder;
    }
    close(bufferBuilder) {
        bufferBuilder.endUnderline();
        return bufferBuilder;
    }
}
