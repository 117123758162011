const counterInitialState={
    counter:0
}


export const ItemCounter =(state=counterInitialState,action)=>{
    switch(action.type){
        case 'INCREMENT_COUNT': 
        console.log('action.payload.data',action.payload)

         if(action.payload.data.id === action.payload.id)
         {
           return  {...state, counter: ++action.payload.data.count};
         }
         break;
        case 'DECREMENT_COUNT':
            if(action.payload.data.count>0){
                if(action.payload.data.id === action.payload.id)
                {
                  return  {...state, counter: --action.payload.data.count};
                }

         }
         break;
        default:
            return state;    
    }

}


const itemslist=[]
export const ItemsListReducer =(state=itemslist, action)=>{
    switch(action.type){
          
         case "ITEMS_LIST_STORING": 
                 console.log('state',state,itemslist)
           return  {...state, itemslist: action.payload};
 
        default:
            return state;    
    }

}

export const  storeCurrencyReducer = (state = {}, action) => {
    switch (action.type) {
        case 'STORE_CURRENCY':
            return {
                ...state,
                storeCurrency: action.payload,
            };
        default:
            return state;
    }
};
const currentDate = new Date();

  // Calculate the date 7 days before
  const sevenDaysBefore = new Date();
  sevenDaysBefore.setDate(currentDate.getDate() - 6);
export const  datefilterreducer = (state = { 
    startDate:sevenDaysBefore,
    endDate:new Date()
}, action) => {
    switch (action.type) {
        case 'START_DATE':
            return {
                ...state,
                startDate: action.payload,
            };
        case 'END_DATE':
         return {
              ...state,
              endDate: action.payload,
          };
           
        default:
            return state;
    }
};
