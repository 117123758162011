import React from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { login } from "../API";
import "react-toastify/dist/ReactToastify.css";
import {
  showErrorToast,
  showSuccessToast,
} from "../components/Toast/configToast";

function Login() {
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .min(6, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      showPassword: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      login(values)
        .then((res) => {
          // console.log("Login Res", res)
          localStorage.setItem("email", values.email);
          localStorage.setItem("password", values.password);
          localStorage.setItem("username", res.data.message.name);
          localStorage.setItem("token", res.data.message.token);
          if(res.data.message.role){
            localStorage.setItem("role", res.data.message.role);
          }
          
          showSuccessToast("Login Successful");
          navigate("/admindashboard");
        })
        .catch((err) => {
          console.log("Login Error", err);
          showErrorToast("Login Error");
        });
    },
  });
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div className="DesktopMain-wrapper">
        <div className="Login_page-wholeMain-wrapper">
          <div className="Login_page_left_side_wrapper">
            <div className="Login_page_left_side_wrapper_img_div">
              <img
                src="/assets/images/Login_page_background_image.png"
                alt=""
              />
            </div>
            <div className="Login_page_left_side_wrapper_Content_div">
              <h2> Autonomous Delivery Robots</h2>
              <img src="assets/images/Image_Login_page.png" alt="" />
            </div>
          </div>
          <div className="LoginPage-Main-wrapper">
            <form onSubmit={formik.handleSubmit} className="Login-page-form">
              <div className="Login-page-form_logo_div">
                <img
                  src="/assets/images/ottonomy-logo-new.png"
                  className="Login-page-form-logo-image"
                  alt=""
                />
              </div>
              <h1 className="Login-page-form_heading_h1"> Let's Get Started</h1>
              <div className="Loginpage=-main-form">
                <div className="Loginpage-main-form-inner-div">
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={
                      {
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //   <PersonIcon style={{color:'#a89f9f'}}/>
                        //   </InputAdornment>
                        // ),
                      }
                    }
                    value={formik.values.email}
                    name="email"
                    onChange={formik.handleChange}
                    placeholder="Email Id"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>

                <div className="Loginpage-main-form-inner-div">
                  <TextField
                    sx={{ borderRadius: "0px", width: "100%" }}
                    fullWidth
                    id="password"
                    variant="standard"
                    name="password"
                    placeholder="Password"
                    type={formik.values.showPassword ? "password" : "text"}
                    InputProps={{
                      // startAdornment: (
                      // <InputAdornment position="start">
                      // <LockIcon style={{color:'#a89f9f'}}/>
                      // </InputAdornment>
                      // ),
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            formik.setFieldValue(
                              "showPassword",
                              !formik.values.showPassword
                            );
                            // console.log(
                            //   "showPassword",
                            //   formik.values.showPassword
                            // );
                          }}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          style={{ color: "#a89f9f" }}
                        >
                          {formik.values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      ),
                    }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </div>
                {/* <div className="Loginpage-main-form-inner-div" > 
               <FormGroup>
                 <FormControlLabel control={<Checkbox />} label="Remember me" />
              </FormGroup>
            </div>  */}
                <div className="Login_page_forgot_password_wrapper">
                  <p> Forgot Password ?</p>
                </div>
              </div>
              <div
                className="Loginpage-btn-wrapper"
                style={{ cursor: "pointer" }}
              >
                <Button type="submit" className="Loginpage-btn-wrapper-btn">
                  {" "}
                  SignIn
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
