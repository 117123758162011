export const zonelistData=[
    {   _id:10101,
        name:'Alcobandas 1',
        fleetId:'636a2d046c36331ecf65ae22',
        stores:20,
        robots:2,
        location:'Spain'
    },
    {   _id:10102,
        name:'Alcobandas 2',
        fleetId:'636a2d046c36331ecf65ae22',
        stores:10,
        robots:1,
        location:'New York'
    },
    {   _id:10103,
        name:'Alcobandas 3',
        fleetId:'636a2d046c36331ecf65ae22',
        stores:5,
        robots:5,
        location:'Pittsburg'
    },
    {   _id:10104,
        name:'Alcobandas 4',
        fleetId:'636a2d046c36331ecf65ae22',
        stores:15,
        robots:2,
        location:'Spain'
    },
    {  _id:10105, 
        name:'Alcobandas 5',
        fleetId:'636a2d046c36331ecf65ae22',
        stores:20,
        robots:2,
        location:'Spain'
    }
]
export const storelistData= [
    {   _id:1021,
        img:'/assets/images/teleop-pizza.webp',
        title:'Tele Pizza',
        description:'Specializes in healthy submarine sandwiches, wraps, salads and drinks',
        zone:'Councourse A, Cincinnati Airport CVG',
        owner:'Arundeep Singh',
        assignfleet:'ADR Mail A',
        pickup:'Telepizza',
    },

]