import {ItemCounter,ItemsListReducer,storeCurrencyReducer,datefilterreducer} from './reducers/index.js'
import { combineReducers, configureStore } from '@reduxjs/toolkit';

const initialState = {}

const mutipleReducers = combineReducers({
    ItemCounter,
    ItemsListReducer,
    storeCurrencyReducer,
    datefilterreducer,
})

const store = configureStore({
    reducer: mutipleReducers,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
export default store







