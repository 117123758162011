import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const TotalOrdersChart = (props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
  };

  const labels = props?.analyticsData?.totalOrdersVsDate?.sort((a, b) => new Date(a.date.split('-').reverse().join('-')) - new Date(b.date.split('-').reverse().join('-')))?.map((order) => order?.date)

  const data = {
    labels,
    datasets: [
      {
        label: "Total Orders",
        data: props?.analyticsData?.totalOrdersVsDate?.sort((a, b) => new Date(a.date.split('-').reverse().join('-')) - new Date(b.date.split('-').reverse().join('-')))?.map(
          (order) => order?.orders
        ),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div className="Chart_wrapper">
      <p>
        Total Orders -{" "}
        <span> {props.analyticsData?.totalOrders}</span>
      </p>
      <Line options={options} data={data} />
    </div>
  );
};

export default TotalOrdersChart;
