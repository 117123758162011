import { Box, Modal } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import NewOrderAlert from "../../alert.wav"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const NewOrder = ({ open,handleacceptorder ,handleClose,confirmOrder ,confirmarr, handleClosetwo}) => {
  // console.log("Confirmed Orders", confirmOrder)
  // console.log("Confirmed Orders", confirmOrder?.filter(order => order.status === "order confirmed").length)
  const navigate = useNavigate();
  const newOrderModalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "auto",
    maxHeight: "calc(100% - 100px)",
    overflow: 'auto',
    width: 480,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={newOrderModalstyle}>
          <div className="NewOrder_modal_wrapper">
          <div className="flex items-center justify-end">
              <div
                onClick={() => {
                 handleClose()
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <CancelOutlinedIcon sx={{ marginBottom: "0px" }} />
                <span> Close</span>
              </div>
            </div>
            <audio
              preload="auto"
              autoPlay="autoplay"
              src={NewOrderAlert}
              disabled={false}
              loop
            ></audio>
            <div className="NewOrder_modal_wrapper_1">
              <img src="" alt="" />
            </div>
            <div className="NewOrder_modal_wrapper_2">
              <h1>New Order</h1>
              <div>
                {/* {console.log('confirmarr',confirmarr)}  */}
                {confirmOrder?.map((item) => {
                  if (item.status === "order confirmed") {
                    return item?.orderData?.map((itm) => {
                      return (
                        <p>
                          {" "}
                          {/* {console.log(item.name)} */}
                          {itm?.name?.includes("x1")
                          ? itm.name.split("x1")[0]
                          : itm?.name} <span> x{itm?.quantity} </span>
                        </p>
                      );
                    });
                  }
                  return null;
                })}
              </div>
              <div className="d_flex_center NewOrder_modal_button_div" style={{
                width: "100%"
              }}>
                {/* <button
                  onClick={() => handleacceptorder(confirmarr)}
                  className="NewOrder_modal_accept_button"
                >
                  {" "}
                  Accept Order
                </button> */}
                <button
                  onClick={() => {
                    navigate("/admindashboard");
                    handleClose();
                  }}
                  style={{
                    width: "100%"
                  }}
                  className="NewOrder_modal_gotoOrder_button"
                >
                  {" "}
                  Go to Order page
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default NewOrder;
