import { TemplateParser } from './template-parser';
import { XMLParser } from './xml-parser';
import { BufferBuilder } from './buffer-builder';
export class EscPos {
    static getBufferFromTemplate(template, data) {
        let templateParser = new TemplateParser();
        return templateParser.parser(template, data).build();
    }
    static getBufferFromXML(xml) {
        let xmlParser = new XMLParser();
        return xmlParser.parser(xml).build();
    }
    static getBufferBuilder() {
        return new BufferBuilder();
    }
}
