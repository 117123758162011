import { XMLNode } from '../xml-node';
import TextNode from './text-node';
export default class TextLineNode extends XMLNode {
    constructor(node) {
        super(node);
        this.textNode = new TextNode(node);
    }
    open(bufferBuilder) {
        return this.textNode.open(bufferBuilder);
    }
    close(bufferBuilder) {
        return this.textNode.close(bufferBuilder).breakLine();
    }
}
