import React, { useEffect } from "react";
import RightContent from "../RightContent";
import { Button } from "@mui/material";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { cancelOrder, updateOrderStatus } from "../../API";
import { showSuccessToast } from "../../components/Toast/configToast";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";

function Inprogress(props) {
  const handleOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setcancelreason("Item Unavailable");
  };
  const [cancelreason, setcancelreason] = useState("Item Unavailable");
  const [canceltestarea, setcanceltextarea] = useState();
  const [cancelledProductList, setCancelledProductList] = useState();

  useEffect(() => {
    let orderProductList = {};
    props?.data?.orderData?.map((order) => {
      orderProductList[
        order.name.includes("x1") ? order.name.split("x1")[0] : order.name
      ] = false;
      setCancelledProductList(orderProductList);
      return null;
    });
  }, []);

  const handlecancelOrder = (data) => {
    props.setcancel(false);
    let checkedCancelledProductList = [];
    cancelledProductList &&
      Object.keys(cancelledProductList).map((product) => {
        if (cancelledProductList[product]) {
          checkedCancelledProductList.push(product);
        }
        return null;
      });
    if (
      cancelreason === "Item Unavailable" &&
      checkedCancelledProductList.length === 0
    ) {
      toast.warn("No specific item was selected for item unavailibilty");
    }

    cancelOrder(
      data.storeId,
      data.orderId,
      canceltestarea,
      cancelreason,
      checkedCancelledProductList
    )
      .then((res) => {
        props.refreshfunction();
        handleClose();
        props.setcancel(true);
        // console.log('Cancel Order Response', res)
        showSuccessToast(res.data.message);
      })
      .catch((err) => {
        console.log("Cancel Order Error", err);
      });
  };

  const handlecanclereason = (e) => {
    setcancelreason(e.target.value);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
    minHeight: "auto",
    maxHeight: "calc(100% - 100px)",
    overflow: "auto",
  };

  // const handleopendoor=(data)=>{
  //   OpenCabinAPI(data).then((res)=>{
  //       toastmsg(res.data.message)
  //   })
  //   .catch((err)=>{
  //      console.log('Error in Open Door',err)
  //   })
  // }

  const handleOrderStatus = (storeId, orderId, orderStatus, statusCode) => {
    let updatedOrderStatus = orderStatus;
    let updatedOrderStatusCode = statusCode;

    if (statusCode === 2) {
      updatedOrderStatusCode = 5;
      updatedOrderStatus = "order picked up";
    } else if (statusCode === 5) {
      updatedOrderStatusCode = 6;
      updatedOrderStatus = "order enroute to dropoff";
    } else if (statusCode === 6) {
      updatedOrderStatusCode = 8;
      updatedOrderStatus = "waiting customer";
    } else if (statusCode === 8) {
      updatedOrderStatusCode = 9;
      updatedOrderStatus = "order delivered";
    }

    updateOrderStatus(
      storeId,
      orderId,
      updatedOrderStatus,
      updatedOrderStatusCode
    )
      .then((res) => {})
      .catch((err) => {
        console.log("Update Order Error", err);
      });
  };

  const getrobotSpawn = (robotSpawn) => {
    switch (robotSpawn) {
      case 0:
        return "trying to spawn robot";
      case 1:
        return "robot will reach pickup soon";
      case 2:
        return "robot spawn has failed please contact support";
      default:
        return "";
    }
  };

  const handleProductChange = (event) => {
    console.log(event.target.value);
    setCancelledProductList({
      ...cancelledProductList,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className="AdminDashboard-tabs-rightside-inner-wrapper">
      <div className="AdminDashboard-tabs-rightside-header">
        <h5 className="rightsideheader-h5">
          {" "}
          {props.data.orderId} <span> {props.data.storeId}</span>
          {!process.env.REACT_APP_ORDERAT.includes("sandbox") && (
            <>
              ,
              <span className="p_colorred">
                {" "}
                ( {props.robotSpawn && props.robotSpawn})
              </span>
            </>
          )}
        </h5>
        <p className="dashboard-tab-inprogress rightsideheader-p">
          {" "}
          {/* {!process.env.REACT_APP_ORDERAT.includes("sandbox") ? (
            <p
              className="dashboard-tab-inprogress rightsideheader-p update__order__status__btn"
              // onClick={() =>
              //   handleOrderStatus(
              //     props.data.storeId,
              //     props.data.orderId,
              //     props.data.status,
              //     props.data.statusCode
              //   )
              // }
            >
              {" "}
              {props.data.status}
            </p>
          ) : ( */}
          <p className="dashboard-tab-inprogress rightsideheader-p update__order__status__btn">
            {" "}
            {props.data.status}
          </p>
          {/* )} */}
          {/* <p
            className="dashboard-tab-inprogress rightsideheader-p update__order__status__btn"
            onClick={() =>
              handleOrderStatus(
                props.data.storeId,
                props.data.orderId,
                props.data.status,
                props.data.statusCode
              )
            }
          >
            {" "}
            {props.data.status}
          </p> */}
          {/* {props.data.status} */}
        </p>
      </div>
      <div className="AdminDashboard-tabs-rightside-content">
        <RightContent data={props.data} storelistData={props?.storelistData} />
        {/* <div className="AdminDashboard-tabs-rightside-content-right-side"> 
         
           {props.data.statusCode==='sfb' ||props.data.statusCode==='asdsvs' ?   <Button className='OpenDoor_active' onClick={()=>handleopendoor(props.data)}> Open Door</Button>
           : <Button className='AdminDashboard-tabs-rightside-content-right-side-btn' disabled> Open Door</Button>}
  
      </div>  */}
      </div>
      <div className="Dashboard-accept-order-button-wrapper-cancel">
        <Button
          className="Dashboard-accept-order-button-cancel"
          onClick={() => handleOpen()}
        >
          {" "}
          Cancel Order
        </Button>
        <Modal
          open={open}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="NewOrder_modal_wrapper">
              <div className="CancelOrder_Modal_heading">
                <h1>Cancel Order</h1>
                <div onClick={handleClose} style={{ cursor: "pointer" }}>
                  {" "}
                  <CancelOutlinedIcon sx={{ marginBottom: "0px" }} />
                  <span> Close</span>
                </div>
              </div>
              <div>
                <div className="d_flex_left cancelOrder_Modal_radio_wrapper">
                  <input
                    type="radio"
                    name="cancelorder"
                    value="Item Unavailable"
                    onChange={(e) => handlecanclereason(e)}
                    defaultChecked
                  />
                  <p> Item Unavailable</p>
                </div>
                {cancelreason === "Item Unavailable" && (
                  <div className="flex flex-col">
                    <FormGroup>
                      {cancelledProductList &&
                        Object.keys(cancelledProductList)?.map((order) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={cancelledProductList[order]}
                                onChange={handleProductChange}
                                size="small"
                                name={order}
                              />
                            }
                            label={order}
                          />
                        ))}
                    </FormGroup>
                  </div>
                )}
                <div className="d_flex_left cancelOrder_Modal_radio_wrapper">
                  <input
                    type="radio"
                    name="cancelorder"
                    value="Unable to service"
                    onChange={(e) => handlecanclereason(e)}
                  />
                  <p> Unable to service</p>
                </div>
                <div className="d_flex_left cancelOrder_Modal_radio_wrapper">
                  <input
                    type="radio"
                    name="cancelorder"
                    value="other"
                    onChange={(e) => handlecanclereason(e)}
                  />
                  <p> Other</p>
                </div>
              </div>
              {cancelreason === "other" ? (
                <div>
                  <textarea
                    className="Cancel_order_modal_textarea"
                    name="canceltestarea"
                    value={canceltestarea}
                    onChange={(e) => {
                      setcanceltextarea(e.target.value);
                    }}
                  ></textarea>
                </div>
              ) : null}

              <div className="CancelOrder_Modal_heading_submit_btn">
                <button
                  onClick={() => handlecancelOrder(props.data)}
                  type="submit"
                >
                  {" "}
                  Submit
                </button>
              </div>
            </div>
          </Box>
        </Modal>
        {/* <Button className="Dashboard-accept-order-button-cancel" onClick={ ()=>handleOpen()}> Cancel with refund</Button> */}
      </div>
    </div>
  );
}

export default Inprogress;
