import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 430,
  bgcolor: '#fff',
  boxShadow: 24,
  p: 4,
  borderRadius:'10px'
};

 function LogoutModal(props) {
  const [, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = (reason) => {
    props.setopen(false)
    if (reason && reason === "backdropClick") 
        return;
     setOpen(false)
 }
 
  const navigate = useNavigate() 
  // const token = localStorage.getItem('token')
  const handlelogoutbtn=()=>{
    localStorage.removeItem('useremail')
    localStorage.removeItem('password')
    localStorage.removeItem('token')
    localStorage.removeItem('fleetID')
    localStorage.removeItem('Isloggedin', "LoginFalse")
    localStorage.removeItem('mapid')
    localStorage.removeItem('Distance')
    localStorage.removeItem('robotId')
    localStorage.removeItem('role')
    localStorage.removeItem('confirmOrderId') 
    localStorage.removeItem('confirmOrderIdmultiple')
    localStorage.removeItem('username')
    localStorage.removeItem('ordercount')
    navigate('/')
  }

  return (
    <>
     
  {/* <img onClick={handleOpen} src="/assets/images/logout-icon.svg"/> */}
     
      <Modal
        open={props.open}
        // BackdropProps={false}
        onHide={handleClose}
        // onClose={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style} >
            <div className='Cancel-order-Modal-Mainwrapper'>
                {/* <img src="/assets/images/cancel-order.png"/> */}
                <p> Are you sure want to logout ?</p>
               <div className="Modal-button-Main-wrapper">
               <button className="Modal-button-Main-btn_yes " onClick={()=>{
               handleClose()
               handlelogoutbtn()
               }}> Yes</button>
               <button className="Modal-button-Main-btn_no" onClick={handleClose}> No</button>
               </div>
           </div> 
        </Box>
      </Modal>
    </>
  );
}
export default React.memo(LogoutModal)