import { XMLNode } from '../xml-node';
export default class WhiteModeNode extends XMLNode {
    constructor(node) {
        super(node);
    }
    open(bufferBuilder) {
        bufferBuilder.startWhiteMode();
        return bufferBuilder;
    }
    close(bufferBuilder) {
        bufferBuilder.endWhiteMode();
        return bufferBuilder;
    }
}
