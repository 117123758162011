import { XMLNode } from '../xml-node';
export default class BreakLineNode extends XMLNode {
    constructor(node) {
        super(node);
    }
    open(bufferBuilder) {
        let lines = 0;
        if (/\d+/.test(this.attributes.lines))
            lines = parseInt(this.attributes.lines) - 1;
        return bufferBuilder.breakLine(lines);
    }
    close(bufferBuilder) {
        return bufferBuilder;
    }
}
