import React, { useEffect } from "react";
import RightContent from "../RightContent";

function CancelOrder(props) {
  // useEffect(()=>{},[props.data])

  return (
    <>
    <div className="AdminDashboard-tabs-rightside-inner-wrapper"> 
                <div className='AdminDashboard-tabs-rightside-header'> 
                      <h5 className="rightsideheader-h5">  {props.data.orderId}  <span> , {props.data.storeId}</span>
                     </h5>
                      <div className='d_flex_center'> 
                        <p className='dashboard-tab-cancel rightsideheader-p'> {props.data.status} </p>
                        {/* <span className='rightsideheader-span'>, {props.data.message}</span> */}
                      </div>
                </div>
                <div className='AdminDashboard-tabs-rightside-content' > 
                <RightContent data={props.data} cancelled={true} storelistData={props?.storelistData}/>
                     {/* <div className="AdminDashboard-tabs-rightside-content-right-side"> 
                        <Button className='AdminDashboard-tabs-rightside-content-right-side-btn active-btndashboard'>  Cancel Order</Button>
                        <Button className='AdminDashboard-tabs-rightside-content-right-side-btn' disabled> Summon Robot</Button>
                        <Button className='AdminDashboard-tabs-rightside-content-right-side-btn' disabled> Open Door</Button>
                    </div>  */}
                </div>
                <div className="Dashboard-accept-order-button-wrapper-cancel">
                  {/* <Button className="Dashboard-accept-order-button-none" disabled> Cancel with refund</Button> */}
                  {/* <Button className="Dashboard-accept-order-button-none" disabled> Cancel Order</Button> */}
                </div>
          </div> 
    </>
  );
}

export default CancelOrder;
