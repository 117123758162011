import { XMLNode } from '../xml-node';
export default class PaperCutNode extends XMLNode {
    constructor(node) {
        super(node);
    }
    open(bufferBuilder) {
        return bufferBuilder.paperCut();
    }
    close(bufferBuilder) {
        return bufferBuilder;
    }
}
