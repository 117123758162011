import { toast } from "react-toastify";

const toastConfig = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const capitalizeMessage = (message) => {
  return message.charAt(0).toUpperCase() + message.slice(1);
};

export const showSuccessToast = (message) => {
  const capitalizedMessage = capitalizeMessage(message);
  toast.success(capitalizedMessage, toastConfig);
};

export const showErrorToast = (message) => {
  const capitalizedMessage = capitalizeMessage(message);
  toast.error(capitalizedMessage, toastConfig);
};

export const showWarnToast = (message) => {
  const capitalizedMessage = capitalizeMessage(message);
  toast.warn(capitalizedMessage, toastConfig);
};