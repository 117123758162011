import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import NotesTwoToneIcon from "@mui/icons-material/NotesTwoTone";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import LogoutModal from "../components/LogoutModal";
import { Badge } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import EditSites from "./Modals/EditSites";
import { CiSettings } from "react-icons/ci";
import { FaEdit } from "react-icons/fa";

function AdminHeader(props) {
  const [open, setopen] = useState(false);
  const [openSiteEditModal, setOpenSiteEditModal] = useState();
  const [storecheck, setstorecheck] = useState(true);
  const [storelist, setstorelist] = useState();
  const [refresh, setrefresh] = useState();
  const [hideWhenClose, setHideWhenClose] = useState();
  const [storeClosedMessagePopup, setStoreClosedMessagePopup] = useState(false);
  const [storeClosedMessage, setStoreClosedMessage] = useState(
    "Store will re-open in 5 minutes."
  );
  const [selectedItem, setSelectedItem] = useState();
  const [customStoreClosedMessage, setCustomStoreClosedMessage] = useState("");
  const [selectedStore, setSelectedStore] = useState();
  const [openStoreEditModal, setOpenStoreEditModal]=useState();

  const handleEditStore=(item)=>{
    setOpenSiteEditModal(true);
      setSelectedStore(item)
  }


  const [state, setState] = React.useState({
    right: false,
  });
  const handleEditSite = () => {
    console.log("Edit clicked")
    setOpenSiteEditModal(true);

  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        className={
          props.zones
            ? `Admin_Header_list_item list_item_active`
            : "Admin_Header_list_item"
        }
      >
        <img src="/assets/images/noun-time-zone-4287131.png" alt="" />
        <Link to="/zones" className={`Header-login-logout-btn`}>
          {" "}
          Zones{" "}
        </Link>
      </div>
      <div
        className={
          props.stores
            ? `Admin_Header_list_item list_item_active`
            : "Admin_Header_list_item"
        }
      >
        <img src="/assets/images/noun-store-103995.png" alt="" />
        <Link to="/adminstores" className={`Header-login-logout-btn`}>
          {" "}
          Stores{" "}
        </Link>
      </div>
      <div
        className={
          props.order
            ? `Admin_Header_list_item list_item_active`
            : "Admin_Header_list_item"
        }
      >
        <img src="/assets/images/noun-order-5401348.png" alt="" />
        <Link to="/admindashboard" className={`Header-login-logout-btn`}>
          {" "}
          Orders {/* orderlist={orderlist} currentStore={currentStore} */}
          {props?.confirmOrder &&
            props?.confirmOrder.filter(
              (order) => order.status === "order confirmed"
            ).length > 0 && (
              <span className="absolute top-0 flex items-center justify-center w-5 h-5 text-xs text-white bg-red-600 rounded-full -right-2">
                {
                  props?.confirmOrder.filter(
                    (order) => order.status === "order confirmed"
                  ).length
                }
              </span>
            )}
        </Link>
      </div>
    </Box>
  );

  return (
    <div className="MainHeader-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-3 logo">
            <Link to="/adminstores">
              <img src="/assets/images/ottonomy-logo.svg" alt="" />
            </Link>
          </div>
          <div className="col-md-9 nav-menu">
            <ul>
              <li className={props.stores ? `list_item_active` : ""}>
                <Link to="/adminstores" className={`Header-login-logout-btn`}>
                  {/* <img src="/assets/images/stote-icon.svg" alt="" /> */}
                  Stores{" "}
                </Link>
              </li>
              <li
                className={
                  props.order ? `list_item_active relative` : "relative"
                }
              >
                <Link
                  to="/admindashboard"
                  className={`Header-login-logout-btn`}
                >
                  {/* <img src="/assets/images/order-icon.svg" alt="" />  */}
                  Orders{" "}
                  {props?.confirmOrder &&
                    props?.confirmOrder.filter(
                      (order) => order.status === "order confirmed"
                    ).length > 0 && (
                      <span className="absolute top-0 flex items-center justify-center w-5 h-5 text-xs text-white bg-red-600 rounded-full -right-2">
                        {
                          props?.confirmOrder.filter(
                            (order) => order.status === "order confirmed"
                          ).length
                        }
                      </span>
                    )}
                </Link>
              </li>
              
              <li className={props.reports ? `list_item_active` : ""}>
                <Link to="/reports" className={`Header-login-logout-btn`} >
                  {/* <img src="/assets/images/order-icon.svg" alt="" /> */}
                  Reports{" "}
                </Link>
              </li>
              <li  onClick={() => handleEditSite("")}>
                
                <div style={{display:"flex"}}>
                
                  <p style={{cursor:"pointer", fontSize:"14px"}} className="Header-login-logout-btn">Settings</p>
                 
                <span>
                <CiSettings className="text-2xl ml-2" style={{cursor:"pointer"}} />
                </span>
                </div>
               




               
              </li>
              <li className="logout-buton">
                <p className="logout-buton_text">
                  {localStorage.getItem("username")
                    ? localStorage.getItem("username")
                    : null}{" "}
                </p>
                <p>
                  {" "}
                  {localStorage.getItem("role") && localStorage.getItem("role")}
                </p>
                <img
                  className="Header-login-logout-btn"
                  onClick={() => setopen(true)}
                  src="/assets/images/logout-icon.svg"
                  alt=""
                />
              </li>
              <LogoutModal open={open} setopen={setopen} />
            </ul>
          </div>
          <div className="Admin_header_Menu_icon">
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button onClick={toggleDrawer(anchor, true)}>
                  <NotesTwoToneIcon />
                </Button>
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>

            ))}
            {/* <EditSites 
             openSiteEditModal={openSiteEditModal}
             setOpenSiteEditModal={setOpenSiteEditModal}/> */}

          </div>
        </div>
      </div>
      <EditSites
                  openSiteEditModal={openSiteEditModal}
                  setOpenSiteEditModal={setOpenSiteEditModal}
                  selectedStore={selectedStore}
                  setstorelist={setstorelist}
                // storeData={storeData}

                />
    </div>

  );
}

export default AdminHeader;
