import { XMLNode } from '../xml-node';
export default class SmallNode extends XMLNode {
    constructor(node) {
        super(node);
    }
    open(bufferBuilder) {
        bufferBuilder.startCompressedCharacter();
        return bufferBuilder;
    }
    close(bufferBuilder) {
        bufferBuilder.endCompressedCharacter();
        return bufferBuilder;
    }
}
