import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const AverageCartChart = (props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
  };

  const labels = props?.analyticsData?.averageCartVsDate
    ?.sort(
      (a, b) =>
        new Date(a.date.split("-").reverse().join("-")) -
        new Date(b.date.split("-").reverse().join("-"))
    )
    ?.map((cart) => cart?.date);

  const data = {
    labels,
    datasets: [
      {
        label: "Average Cart",
        data: props?.analyticsData?.averageCartVsDate
          ?.sort(
            (a, b) =>
              new Date(a.date.split("-").reverse().join("-")) -
              new Date(b.date.split("-").reverse().join("-"))
          )
          ?.map((cart) => cart?.cartValues),
        borderColor: "rgb(0, 255, 0)",
        backgroundColor: "rgba(0, 255, 0, 0.5)",
      },
    ],
  };

  return (
    <div className="Chart_wrapper">
      <p>
        {" "}
        Average Cart Values -{" "}
        <span>
          {props?.currentCurrency}
          {props?.analyticsData?.averageCartValue?.toFixed(2)}
        </span>
      </p>
      <Line options={options} data={data} />
    </div>
  );
};

export default AverageCartChart;
