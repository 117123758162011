import Mustache from 'mustache';
import { XMLParser } from './xml-parser';
export class TemplateParser {
    constructor() {
        this.mustache = Mustache;
    }
    parser(template, scope) {
        const xml = this.mustache.render(template, scope);
        return new XMLParser().parser(xml);
    }
}
