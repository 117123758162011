import { Box, Modal } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import NewOrderAlert from "../../alert.wav"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const MultipleOrder = ({handleClosetwo,opentwo,confirmOrder}) => {
    
    const navigate =useNavigate()
    const newOrderModalstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minHeight: "auto",
        maxHeight: "calc(100% - 100px)",
        // height: confirmOrder?.filter(order => order.status === "order confirmed").length > 5  ? 'calc(100% - 50px)' : 'auto',
        overflow: 'auto',    
        width: 480,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius:2,
        p: 4,
      };
      let ordercount=0
  return (
    <div>
      <Modal
        open={opentwo}
        onClose={handleClosetwo}
       
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={newOrderModalstyle}>  
           <div className="NewOrder_modal_wrapper"> 
           <div className="flex items-center justify-end">
              <div
                onClick={() => {
                 handleClosetwo()
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <CancelOutlinedIcon sx={{ marginBottom: "0px" }} />
                <span> Close</span>
              </div>
            </div>
               <div className='NewOrder_modal_wrapper_1'>  
                <img src="" alt=""/>
              </div> 
              <audio preload="auto" autoPlay='autoplay' src={NewOrderAlert} disabled={false} loop></audio>
              <div className='NewOrder_modal_wrapper_2'> 
                <h1>
                  {(()=>{
                    confirmOrder?.map((item)=>{
                      if(item.status ==='order confirmed'){
                       return ordercount++
                      }
                    })
                  })()}
                  {ordercount}</h1>
                <h1>New Orders</h1>
                 <div className='d_flex_start NewOrder_modal_button_div'>   
                  <button style={{
                    width: "100%"
                  }} className='NewOrder_modal_gotoOrder_button' onClick={()=>{
                    navigate('/admindashboard')
                    handleClosetwo()
                  }}> Go to Order page</button>   
                 </div>
              </div>
          </div>     
        </Box>
      </Modal>
    </div>
  )
}

export default MultipleOrder
