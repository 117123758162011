export const IncrementCount =(data , id)=>{
    return{
        type: "INCREMENT_COUNT",
        payload:{data,id}
    }
}

export const DecrementCount =(data ,id)=>{
    return{
        type: "DECREMENT_COUNT",
        payload:{data,id }
    }
}


export const ItemsList =(data)=>{
  return {
    type:"ITEMS_LIST_STORING",
    payload:data
  }
}
export const storecurrency=(currency) => async (dispatch) =>{
  dispatch({
    type:'STORE_CURRENCY',
    payload:currency
})
}
export const startdate=(startDate) =>  (dispatch) =>{
  dispatch({
    type:'START_DATE',
    payload:startDate
})
}
export const enddate=(endDate) =>  (dispatch) =>{
  dispatch({
    type:'END_DATE',
    payload:endDate
})
}