import { XMLNode } from '../xml-node';
export default class LineFeedNode extends XMLNode {
    constructor(node) {
        super(node);
    }
    open(bufferBuilder) {
        return bufferBuilder.lineFeed();
    }
    close(bufferBuilder) {
        return bufferBuilder;
    }
}
